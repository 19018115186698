<template>
  <div>
    <core-modal-header>Show/Hide People</core-modal-header>

    <core-modal-body>
      <div class="flex-1 overflow-y-auto scrollbar-light -mr-2 sm:-mr-4 pr-2 sm:pr-4 scrollbar-gutter-stable max-h-[72vh]" ref="listEl">
        <transition-group name="f-slide-fade">
          <core-contextual-loading-box v-if="pending" key="loading" loading-text="Loading People" />
          <core-contextual-error-box v-else-if="error" key="error" :action="refresh" />

          <core-empty-state
            v-else-if="!people.length"
            heading="No People"
            description="No people were found that match your criteria."
            :icon="COMMON_ICONS.person"
          />

          <core-list v-else :items="people" :scroller-elem="listEl" :disable-approach-bottom="pending || isLoadingMore || !hasMore" @approach-bottom="getMore" container-class="grid grid-cols-3 xs:grid-cols-4 md:grid-cols-6" grid-gap-class="gap-3 sm:gap-6">
            <template #default="{ item: person }">
              <people-list-item
                :person="person"
                :key="person.id"
                text-size="text-sm"
                disable-edit
                @item-click="toggleItem(person)"
              />
            </template>
          </core-list>
        </transition-group>
      </div>

    </core-modal-body>

  </div>

</template>

<script setup>
  import {makePeopleStore} from '~/stores/people.js';
  import {storeToRefs} from 'pinia';

  const listEl = ref();

  const peopleStoreId = 'showHidePeopleStore';
  const peopleStore = makePeopleStore(peopleStoreId)();
  const {people, hasMore} = storeToRefs(peopleStore);

  const {refresh, pending, error} = await useLazyAsyncData(
    'show-hide-people',
    () => peopleStore.getPeople({
      displayStatus: [PEOPLE_DISPLAY_STATUS.visible, PEOPLE_DISPLAY_STATUS.hidden],
      order: PEOPLE_SORT_OPTIONS[0].param
    })
  );

  const isLoadingMore = ref(false);

  async function getMore() {
    if (!hasMore.value) {
      return;
    }

    isLoadingMore.value = true;

    try {
      await peopleStore.getPeople({nextPage: true});
    } catch (e) {
      useErrorToast().add('There was an issue getting more people.');
    } finally {
      isLoadingMore.value = false;
    }
  }

  function toggleItem(person) {
    peopleStore.updatePerson({
      id: person.id,
      updates: {
        display_status: person.isVisible
          ? PEOPLE_DISPLAY_STATUS.hidden
          : PEOPLE_DISPLAY_STATUS.visible
      }
    });
  }

  onUnmounted(() => {
    peopleStore.clearPeople();
  });
</script>
