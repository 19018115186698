<template>
  <div class="relative">
    <u-button  color="charcoal" variant="soft" size="xs" class="absolute top-3.5 left-3.5" @click="edit">Edit Details</u-button>

    <div class="p-3 sm:p-6 lg:p-10">

      <div class="flex flex-col gap-2">
        <div class="w-36 sm:w-52 h-36 sm:h-52 bg-gray-200 rounded-full flex items-center justify-center mx-auto">
          <people-avatar
            :person="person"
            size="md"
            :ui="{wrapper: 'border-2 border-white shadow-xl !w-full !h-auto aspect-[1/1]', size: {md: '!w-full !h-auto aspect-[1/1]'}}"
          />
        </div>
        <h3 class="font-serif text-center text-3xl md:text-5xl py-1">{{person.name}}</h3>
        <p v-if="person.fullDateDisplay" class="text-charcoal-400 text-base sm:text-lg text-center">{{person.fullDateDisplay}}</p>

        <u-accordion :items="accordionItems" v-if="showAdditionalDetails" class="mt-4" :ui="{item: {base: 'mt-4'}}">
          <template #default="{item, open}">
            <div class="text-center">
              <u-button  color="white"  size="xs" trailing-icon="i-ri-arrow-down-s-line">Additional Details</u-button>
            </div>
          </template>

          <template #additional_details>
            <div class="text-center xs:text-left border border-gray-200 rounded p-4 text-charcoal grid text-sm leading-none bg-shell-100 gap-5" :class="person.person_alternate_names?.length ? 'grid-cols-9' : 'grid-cols-6'">

              <div class="col-span-9 xs:col-span-4 sm:col-span-2">
                <div class="flex gap-2 items-center -mb-2 justify-center xs:justify-start">
                  <u-icon name="i-ri-user-line" class="w-5 h-5 leading-none" />
                  <span class="font-semibold leading-none text-base mt-px">Full Name</span>
                </div>

                <div class="flex flex-col leading-none pl-7 mt-5" v-if="person.title">
                  <span class="text-gray-500 mb-1">Title</span>
                  <span>{{person.title}}</span>
                </div>
                <div class="flex flex-col leading-none pl-7 mt-5" v-if="person.first_name">
                  <span class="text-charcoal-400 mb-1">First Name</span>
                  <span>{{person.first_name}}</span>
                </div>
                <div class="flex flex-col leading-none pl-7 mt-5" v-if="person.middle_name">
                  <span class="text-charcoal-400 mb-1">Middle Name(s)</span>
                  <span>{{person.middle_name}}</span>
                </div>
                <div class="flex flex-col leading-none pl-7 mt-5" v-if="person.last_name">
                  <span class="text-charcoal-400 mb-1">Last Name</span>
                  <span>{{person.last_name}}</span>
                </div>
                <div class="flex flex-col leading-none pl-7 mt-5" v-if="person.suffix">
                  <span class="text-charcoal-400 mb-1">Suffix</span>
                  <span>{{person.suffix}}</span>
                </div>
              </div>

              <div v-if="person.person_alternate_names?.length" class="col-span-9 xs:col-span-5 sm:col-span-3">
                <div class="flex gap-2 items-center -mb-2 justify-center xs:justify-start">
                  <u-icon name="i-ri-user-add-line" class="w-5 h-5 leading-none" />
                  <span class="font-semibold leading-none text-base mt-px">Alternative Names</span>
                </div>

                <div class="flex flex-col leading-none pl-7 mt-5" v-for="name in person.person_alternate_names">
                  <span class="text-charcoal-400 mb-1">{{name.display_type}}</span>
                  <span>{{name.display_name}}</span>
                </div>
              </div>

              <div class="col-span-9 xs:col-span-4 sm:col-span-2">
                <div class="flex gap-2 items-center mb-1 justify-center xs:justify-start">
                  <u-icon name="i-ri-pulse-line" class="w-5 h-5 leading-none" />
                  <span class="font-semibold leading-none text-base mt-px">Life Status</span>
                </div>
                <p class="pl-7">{{person.life_status_display}}</p>

                <div class="flex gap-2 items-center mt-5 mb-1 justify-center xs:justify-start">
                  <u-icon name="i-ri-calendar-line" class="w-5 h-5 leading-none" />
                  <span class="font-semibold leading-none text-base mt-px">Birth Date</span>
                </div>
                <p class="pl-7">{{person.birth_date ? $dayjs(person.birth_date).format('LL') : '–'}}</p>

                <div class="flex gap-2 items-center mt-5 mb-1 justify-center xs:justify-start">
                  <u-icon name="i-ri-calendar-line" class="w-5 h-5 leading-none" />
                  <span class="font-semibold leading-none text-base mt-px">Death Date</span>
                </div>
                <p class="pl-7">{{person.death_date ? $dayjs(person.death_date).format('LL') : '–'}}</p>
              </div>

              <div class="col-span-9 xs:col-span-4 sm:col-span-2">
                <div class="flex gap-2 items-center mb-1 justify-center xs:justify-start">
                  <u-icon :name="person.gender === 'male' ? 'i-ri-men-line' : person.gender === 'female' ? 'i-ri-women-line' : 'i-ri-genderless-line'" class="w-5 h-5 leading-none" />
                  <span class="font-semibold leading-none text-base mt-px">Gender</span>
                </div>
                <p class="pl-7">{{person.gender_string || '–'}}</p>

                <div class="flex gap-2 items-center mt-5 mb-1 justify-center xs:justify-start">
                  <u-icon name="i-ri-fingerprint-line" class="w-5 h-5 leading-none" />
                  <span class="font-semibold leading-none text-base mt-px">Pronouns</span>
                </div>
                <p class="pl-7">{{person.pronouns?.label || '–'}}</p>
              </div>

            </div>
          </template>

        </u-accordion>
      </div>

      <div class="bg-white/75 px-5 pt-5" v-if="person.bio">
        <core-fancy-text-display :text="person.bio" />
      </div>
    </div>
  </div>
</template>

<script setup>
  const props = defineProps({
    person: Object,
    modal: Object
  });

  const accordionItems = [
    {label: 'Additional Details', slot: 'additional_details'}
  ];

  function edit() {
    usePeopleActions().addEdit({person: props.person})
  }

  const showAdditionalDetails = computed(() => {
    return props.person.gender || props.person.preferred_pronouns || props.person.person_alternate_names?.length;
  });
</script>
