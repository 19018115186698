<template>
  <u-form :state="state" @submit="savePerson" ref="form" :validate-on="['submit']">
    <core-modal-header>{{isEditing ? 'Edit Person' : 'Create Person'}}</core-modal-header>

    <core-modal-body>
      <u-accordion :items="accordionItems" :ui="accordionConfig">
        <template #default="{item, open}">
          <div class="flex justify-between items-center select-none cursor-pointer bg-gray-100 hover:bg-gray-200/75 transition-colors rounded px-2 -mx-2 py-1">
            <label class="font-semibold text-base cursor-pointer">{{item.label}}</label>
            <u-icon name="i-ri-arrow-down-s-line" class="text-xl transition-all" :class="open && '-rotate-180'" />
          </div>
        </template>

        <template #name>
          <div>
            <div class="grid grid-cols-6 gap-2">
              <div class="col-span-1">
                <u-form-group name="title">
                  <u-input v-model="state.title" placeholder="Title" />
                </u-form-group>
              </div>

              <div class="col-span-5">
                <u-form-group name="first_name">
                  <u-input v-model="state.first_name" placeholder="First Name" />
                </u-form-group>
              </div>

              <div class="col-span-6">
                <u-form-group name="middle_name">
                  <u-input v-model="state.middle_name" placeholder="Middle Name(s)" />
                </u-form-group>
              </div>

              <div class="col-span-5">
                <u-form-group name="last_name">
                  <u-input v-model="state.last_name" placeholder="Last Name" />
                </u-form-group>
              </div>

              <div class="col-span-1">
                <u-form-group name="suffix">
                  <u-input v-model="state.suffix" placeholder="Suffix" />
                </u-form-group>
              </div>
            </div>

            <u-form-group name="display_name" class="mt-2">
              <u-input v-model="state.display_name" placeholder="Display Name (this will appear wherever this person is shown)" />
            </u-form-group>

            <u-form-group class="mt-5">
              <div class="flex justify-between items-end mb-1">
                <label class="font-semibold text-sm text-charcoal" v-if="visibleAlternativeNames.length">Alternative Names</label>
                <u-button :padded="false" @click="addAlternativeNameModal" size="2xs" variant="link" trailing-icon="i-ri-add-circle-line">Add Alternative Name</u-button>
              </div>

              <div v-if="visibleAlternativeNames.length" class="relative overflow-x-auto">
                <table class="w-full max-w-full  text-sm text-left text-gray-500 border border-collapse">
                  <thead class="text-xs text-gray-700 uppercase bg-gray-50 border-b">
                  <tr>
                    <th scope="col" class="px-3 py-2">Name</th>
                    <th scope="col" class="px-3 py-2">Name Type</th>
                    <th scope="col" class="px-3 py-2"></th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="name in visibleAlternativeNames" class="bg-white border-b hover:bg-gray-50">
                    <th scope="row" class="px-3 py-2 font-semibold text-gray-900">
                      <span class="flex break-long-string">{{name.display_name}}</span>
                    </th>
                    <td class="px-3 py-2">{{PERSON_NAME_TYPE_OPTIONS.find(item => item.value === name.name_type).label}}</td>
                    <td class="px-3 py-2 text-right flex gap-2 justify-end">
                      <core-responsive-button @click="editName(name)" size="2xs" variant="soft" color="charcoal" :icon="COMMON_ICONS.edit">Edit</core-responsive-button>
                      <core-responsive-button @click="deleteName(name)" size="2xs" variant="soft" color="charcoal" :icon="COMMON_ICONS.delete">Delete</core-responsive-button>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </u-form-group>

          </div>
        </template>

        <template #additional_details>
          <section>
            <u-form-group name="bio" label="Bio" class="mb-5">
              <u-textarea v-model="state.bio" :rows="6" :maxrows="12" autoresize placeholder="Tell us about this person." />
            </u-form-group>

            <div class="flex flex-col sm:flex-row sm:justify-between sm:items-end gap-2 mb-5">
                <u-form-group name="gender" label="Gender">
                  <u-radio-group
                    v-model="state.gender"
                    :options="PERSON_GENDER_OPTIONS"
                    :ui="{fieldset: 'flex gap-5'}"
                    :ui-radio="{inner: 'ms-1.5'}"
                  />
                </u-form-group>

                <u-select-menu v-model="state.preferred_pronouns" :options="PEOPLE_PRONOUN_OPTIONS" value-attribute="value">
                  <u-button  size="xs" variant="link" :padded="false" trailing-icon="i-ri-settings-3-line">Customize Pronouns</u-button>
                </u-select-menu>
              </div>

            <u-form-group name="life_status" label="Status" class="mb-5">
              <u-radio-group
                v-model="state.life_status"
                :options="PERSON_LIFE_STATUS_OPTIONS"
                :ui="{fieldset: 'flex gap-5'}"
                :ui-radio="{inner: 'ms-1.5'}"
              />
            </u-form-group>

            <div class="grid grid-cols-2 gap-2">
              <u-form-group label="Birthdate" name="birth_date">
                <core-date-picker
                  v-model="state.birth_date"
                  timezone="UTC"
                />
              </u-form-group>
              <transition name="f-fade">
                <u-form-group label="Death date" name="death_date" v-if="state.life_status === PERSON_LIFE_STATUS_OPTIONS[1].value">
                  <core-date-picker
                    v-model="state.death_date"
                    timezone="UTC"
                  />
                </u-form-group>
              </transition>
            </div>

          </section>

        </template>
      </u-accordion>
    </core-modal-body>

    <core-modal-footer>
      <u-button color="charcoal" variant="ghost" v-if="modal" @click="modal.close()">Cancel</u-button>
      <u-button type="submit" :loading="isSaving">{{isEditing ? 'Update Person' : 'Create Person'}}</u-button>
    </core-modal-footer>
  </u-form>
</template>

<script setup>
  import cloneDeep from 'lodash.clonedeep';
  import {PeopleEditAltNamesForm} from '#components';

  const emit = defineEmits(['success']);
  const props = defineProps({
    person: Object,
    modal: Object
  });

  const isSaving = ref(false);
  const isEditing = Boolean(props.person);
  const form = ref();
  const state = ref(Object.assign(
    {
      title: null,
      first_name: null,
      middle_name: null,
      last_name: null,
      suffix: null,
      display_name: null,
      bio: null,
      birth_date: null,
      death_date: null,
      gender: null,
      life_status: PERSON_LIFE_STATUS_DEFAULT,
      preferred_pronouns: null,
      person_alternate_names: []
    },
    cloneDeep(props.person)
  ));

  const visibleAlternativeNames = computed(() => state.value.person_alternate_names.filter(n => !n._destroy));

  const accordionItems = [
    {label: 'Name', slot: 'name', defaultOpen: true},
    {label: 'Additional Details', slot: 'additional_details'}
  ];

  const accordionConfig = {
    wrapper: 'flex flex-col gap-2',
    item: {
      padding: 'p-0 pt-3 pb-1.5'
    }
  };

  async function savePerson() {
    isSaving.value = true;

    try {

      //clear death_date if life_status is living
      if (state.value.life_status === PERSON_LIFE_STATUS_OPTIONS[0].value) {
        state.value.death_date = null;
      }

      const newPerson = isEditing
        ? await usePeopleStore().updatePerson({id: state.value.id, updates: state.value})
        : await usePeopleApi().createPerson({person: state.value});

      useSuccessToast().add({
        id: 'person-edit-success',
        description: `Your person ${html2text(newPerson.name)} was saved.`,
        actions: [
          !isEditing ? {
            label: 'View Person',
            click: () => navigateTo(useBuildRoute().toPerson({person: newPerson}))
          } : null
        ].filter(Boolean)
      });

      emit('success', {newPerson});
    } catch (e) {
      useErrorToast().add();
    } finally {
      isSaving.value = false;
    }
  }

  function editName(name) {
    addAlternativeNameModal({name});
  }

  function deleteName(name) {
    name._destroy = true;
  }

  function addAlternativeNameModal({name}) {
    const index = state.value.person_alternate_names.findIndex(item => JSON.stringify(item) === JSON.stringify(name));

    return new Promise(async (resolve) => {
      const modal = await useFModal({
        id: 'add-alt-name',
        component: PeopleEditAltNamesForm,
        clickToClose: false,
        props: {name},
        eventHandlers: {
          success(result) {
            modal.close();

            delete result.display_name;

            const updatedAlternateName = {
              ...result,
              display_name: getPersonDisplayName(result)
            };

            if (index !== -1) {
              // Replace the existing name with the updated one
              state.value.person_alternate_names.splice(index, 1, updatedAlternateName);
            } else {
              // Add the new name to the array
              state.value.person_alternate_names.push(updatedAlternateName);
            }

            resolve();
          }
        }
      }).open();
    });
  }
</script>
