<template>
  <u-form :state="state" :schema="schema" @submit="saveName" ref="form" :validate-on="['submit']">
    <core-modal-header>{{isEditing ? `Edit Alternative Name` : `Add Alternative Name`}}</core-modal-header>

    <core-modal-body>

      <u-form-group label="Name Type" name="name_type" class="mb-4">
        <u-select-menu v-model="state.name_type" value-attribute="value" :options="PERSON_NAME_TYPE_OPTIONS" />
      </u-form-group>

      <label class="text-sm font-semibold">Name</label>
      <div class="grid grid-cols-6 gap-2">
        <div class="col-span-1">
          <u-form-group name="title">
            <u-input v-model="state.title" placeholder="Title" />
          </u-form-group>
        </div>

        <div class="col-span-5">
          <u-form-group name="first_name" required>
            <u-input v-model="state.first_name" placeholder="First Name" />
          </u-form-group>
        </div>

        <div class="col-span-6">
          <u-form-group name="middle_name">
            <u-input v-model="state.middle_name" placeholder="Middle Name(s)" />
          </u-form-group>
        </div>

        <div class="col-span-5">
          <u-form-group name="last_name">
            <u-input v-model="state.last_name" placeholder="Last Name" />
          </u-form-group>
        </div>

        <div class="col-span-1">
          <u-form-group name="suffix">
            <u-input v-model="state.suffix" placeholder="Suffix" />
          </u-form-group>
        </div>
      </div>

    </core-modal-body>

    <core-modal-footer>
      <u-button color="charcoal" variant="ghost" v-if="modal" @click="modal.close()">Cancel</u-button>
      <u-button type="submit">{{isEditing ? 'Save Name' : 'Add Name'}}</u-button>
    </core-modal-footer>
  </u-form>
</template>

<script setup>
  import {object, string} from 'yup';

  const emit = defineEmits(['success']);
  const props = defineProps({
    name: Object,
    modal: Object
  });

  const isEditing = Boolean(props.name);

  const form = ref();
  const state = ref(Object.assign(
    {
      title: null,
      first_name: null,
      middle_name: null,
      last_name: null,
      suffix: null,
      name_type: PERSON_NAME_TYPE_DEFAULT,
    },
    props.name
  ));

  const schema = object({
    first_name: string().required('First Name is required.')
  });

  async function saveName() {
    emit('success', state.value);
  }
</script>
