<template>
  <div>
    <core-modal-header>Hide Person?</core-modal-header>

    <core-modal-body>
      <p>Are you sure you want to hide <span :class="person.name ? 'font-semibold' : null">{{person.name || 'this person'}}</span>?</p>

      <u-alert
        icon="i-ri-error-warning-line"
        class="mt-4"
        color="blue"
        description="Files will not be affected and will remain in your Gallery."
      />
    </core-modal-body>

    <core-modal-footer>
      <u-button color="charcoal" variant="soft" @click="modal.close()">Cancel</u-button>
      <u-button @click="confirm" :loading="isSaving">Yes, Hide</u-button>
    </core-modal-footer>

  </div>
</template>

<script setup>
  const emit = defineEmits(['success']);
  const props = defineProps({
    person: Object,
    modal: Object
  });

  const isSaving = ref(false);

  async function confirm() {
    try {
      isSaving.value = true;

      await usePeopleStore().updatePerson({
        id: props.person.id,
        updates: {
          display_status: PEOPLE_DISPLAY_STATUS.hidden
        }
      });
      emit('success');
    } catch (e) {
      useErrorToast().add();
    } finally {
      isSaving.value = false;
    }
  }
</script>
