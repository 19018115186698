<template>
  <div @keydown.esc.stop @keyup.esc.stop>
    <u-select-menu
      name="person-select"
      v-model="selected"
      @update:model-value="select"
      v-model:query="inputStr"
      :searchable="getPeople"
      :searchable-lazy="true"
      :searchable-placeholder="placeholder"
      clear-search-on-close
      multiple
      :disabled="disabled"
      :ui-menu="uiMenu"
      :ui="ui"
      :popper="{arrow: true, placement: 'bottom'}"
    >
      <!-- note: select multiple is necessary because otherwise a click away form the dropdown results in selecting the highlighted option -->

      <template #option="{option}">
        <template v-if="option.isCreate">
          <u-avatar :icon="COMMON_ICONS.edit" size="md" :ui="{background: 'bg-charcoal-200 dark:bg-charcoal'}" />
          <span>Rename to "{{inputStr}}"</span>
        </template>
        <template v-else-if="option.isRemove">
          <u-avatar :icon="COMMON_ICONS.delete" size="md" :ui="{background: 'bg-charcoal-900 dark:bg-charcoal'}" />
          <span>Remove Person From Photo</span>
        </template>

        <template v-else>
          <people-avatar :person="option" size="md" />
          {{option.name}}
        </template>
      </template>

      <template #option-empty>
        <div class="flex gap-2" v-if="isLoading">
          <u-icon :name="COMMON_ICONS.loading" class="animate-spin text-xl" />
          <span>Loading</span>
        </div>
        <template v-else>
          No people found.
        </template>
     </template>

      <template #empty>
        No people found.
      </template>

      <div ref="targetWrapperEl">
        <slot />
      </div>

    </u-select-menu>
  </div>
</template>

<script setup>
  import cloneDeep from 'lodash.clonedeep';

  const emit = defineEmits(['merged', 'updated', 'remove']);
  const props = defineProps({
    person: Object,
    size: {
      type: String,
      default: 'md'
    },
    confirmMerge: {
      type: Boolean,
      default: true
    },
    filterPeopleIds: Array,
    allowReassociateVisible: Boolean,
    disassociateMethod: Function,
    allowRemove: Boolean,
    renameOnly: Boolean,
    disabled: Boolean,
    ui: Object
  });

  const isLoading = ref(false);
  const peopleApi = usePeopleApi();
  const peopleStore = usePeopleStore();
  const peopleActions = usePeopleActions();
  const selected = ref([]);
  const targetWrapperEl = ref();
  const sourcePerson = ref(null);

  const inputStr = ref('');
  const createOption = {
    isCreate: true
  };
  const removeOption = {
    isRemove: true
  };
  let shouldReassociate = false; //tracker flag for logic flow

  const uiMenu = computed(() => {
    const emptyNameOnlyProps = (props.renameOnly || props.person.isCurrentUser) && !inputStr.value
      ? {
        empty: 'hidden',
        input: 'border-b-0 my-0'
      }
      : {};

    return {
      container: 'min-w-80',
      height: 'max-h-80',
      base: 'scrollbar-light',
      trigger: 'block h-full',
      ...emptyNameOnlyProps
    };
  });

  const placeholder = computed(() => {
    if (props.allowReassociateVisible) {
      return 'Search to reassign to another person.'
    }

    if (props.renameOnly || props.person.isCurrentUser) {
      return 'Enter a name';
    }

    return props.person?.name ? 'Enter a new name or merge' : 'Enter a name or merge'
  });

  watch(inputStr, () => (isLoading.value = true));

  async function getPeople(search) {
    isLoading.value = true;

    if (!props.allowReassociateVisible && (props.renameOnly || props.person.isCurrentUser)) {
      isLoading.value = false;

      return search ? [createOption] : [];
    }

    const data = await usePeopleApi().getPeople({
      search,
      order: PEOPLE_SORT_OPTIONS[2].param,
      namedStatus: PEOPLE_NAMED_STATUS.named
    });

    //filter people, including current person if provided
    const filteredIds = props.filterPeopleIds || [];

    if (props.person) {
      filteredIds.push(props.person.id);
    }

    const people = data.people.filter(p => !filteredIds.includes(p.id));

    // Limit the number of results to 3 when searching, otherwise show all
    const filteredPeople = !search
      ? people
      : people.filter(p => getPersonDisplayName(p).toLowerCase().includes(search.toLowerCase()));

    if (search) {
      if (!props.allowReassociateVisible) {
        filteredPeople.unshift(createOption);
      }
    } else if (props.allowRemove) {
      filteredPeople.unshift(removeOption);
    }

    isLoading.value = false;

    return filteredPeople;
  }

  async function select(options) {
    if (isLoading.value) {
      selected.value = [];
      return;
    }

    const option = options[options.length - 1];

    if (!option) {
      return;
    }

    if (option.isRemove) {
      emit('remove', props.person);
      return;
    }
    sourcePerson.value = cloneDeep(props.person);

    try {
      if (props.allowReassociateVisible && props.person.isVisible) {
        await props.disassociateMethod(sourcePerson.value);
        shouldReassociate = true;
      }

      if (props.person.isDisassociated || shouldReassociate) {
        sourcePerson.value = await peopleApi.createPerson({
          person: {
            person_file_id: props.person.person_file_id
          }
        });
      }
    } catch (e) {
      useErrorToast().add();

      throw e;
    }

    if (option.isCreate) {
      namePerson(inputStr.value);
    } else {
      selectPerson(option);
    }

    if (targetWrapperEl.value) {
      targetWrapperEl.value.click();
    }

    selected.value = [];
  }

  async function namePerson(name) {
    let nameUpdates;

    if (props.person.display_name) {
      nameUpdates = {
        display_name: name
      };
    } else {
      nameUpdates = {...parseName(name)};
    }

    try {
      await peopleStore.updatePerson({
        id: sourcePerson.value.id,
        updates: {
          ...nameUpdates,
          display_status: PEOPLE_DISPLAY_STATUS.visible
        }
      });

      useSuccessToast().add({
        description: 'Name updated successfully.'
      });

      emit('updated');
    } catch (e) {
      useErrorToast().add();
    }
  }

  async function selectPerson(selectedPerson) {
    if (props.confirmMerge && !shouldReassociate) {
      const {merged} = await peopleActions.confirmPersonMerge({
        person: sourcePerson.value,
        toPerson: selectedPerson
      });

      if (merged) {
        useSuccessToast().add({
          description: 'The merge was performed successfully.'
        });

        emit('merged', {person: selectedPerson});
        emit('updated');
      }
    } else {
      await peopleStore.mergePeople({
        source: sourcePerson.value,
        destination: selectedPerson
      });
      emit('merged', {person: selectedPerson});
      emit('updated');
    }
  }
</script>
